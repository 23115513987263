import request from '@/utils/request'
// import qs from 'qs'

export function getCardList(data) {
  return request({
    url: '/finance-center/finance/user/getCardList',
    method: 'post',
    data
  })
}
export function bindCard(data) {
  return request({
    url: '/finance-center/finance/user/bindCard',
    method: 'post',
    data
  })
}
// 用户解绑银行卡
export function unbindCard(data) {
  return request({
    url: '/finance-center/finance/user/unbindCard',
    method: 'post',
    data
  })
}
export function getBankName(bankNo) {
  return request({
    url: '/api-finance/finance/user/getBankName?bankNo=' + bankNo,
    passCheck: true,
    method: 'post'
  })
}

// 通过orderid获取绑定的银行卡
export function getCardByOrderId(params) {
  return request({
    url: '/api-finance/finance/user/getCardByOrderId',
    method: 'get',
    params
  })
}

// 根据orderid,carid上传我的产品
export function bindOrderCard(data) {
  return request({
    url: '/api-finance/finance/user/bindOrderCard',
    method: 'post',
    data
  })
}

