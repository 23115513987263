<template>
  <div class="bank-list">
    <div class="head">
      <div class="title">我的卡</div>
      <div class="num">共{{ list.length }}张</div>
    </div>
    <div class="list">
      <div v-for="item in list" :key="item.id" class="item" @click="selectBank(item)">
        <div class="icon"><img :src="item.bankLogo" alt=""></div>
        <div class="cont">
          <div class="tt">{{ item.bankName }}</div>
          <div class="ct">{{ item.cardNo }}</div>
        </div>
        <div v-if="status != 1" class="del" @click="goDel(item)">
          <img src="@/assets/user/delete@2x.png" alt="">
        </div>
      </div>
    </div>
    <div class="bottom" @click="goAddBank">
      <div class="title">添加银行卡</div>
      <div class="icon"><van-icon name="add-o" /></div>
    </div>
  </div>
</template>
<script>
import { getCardList, unbindCard } from '@/api/finance'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      list: [],
      status: 1 // 1选择银行卡  0 我的银行卡
    }
  },
  computed: {
    ...mapGetters([
      'userInfos'
    ])
  },
  mounted() {
    this.status = this.$route.query.status || 0
    if (this.status === '1') {
      document.title = '选择银行卡'
    }
    this.getList()
  },
  methods: {
    selectBank(item) {
      if (this.status === 0) return
      this.$dialog.confirm({
        title: '标题',
        confirmButtonText: '是',
        cancelButtonText: '否',
        confirmButtonColor: '#FF7700',
        message: '您确定要选择此卡作为手续费代扣银行卡吗？'
      })
        .then(() => {
        // on confirm
          this.$store.dispatch('user/selectBank', item)
          this.$router.go(-1)
        })
        .catch(() => {
        // on cancel
        })
    },
    async goDel(item) {
      this.$dialog.confirm({
        message: '您是否确定解绑银行卡',
        confirmButtonText: '否',
        cancelButtonText: '是',
        cancelButtonColor: '#FF7700'
      })
        .then(async() => {
        })
        .catch(async() => {
          await unbindCard({ cardId: item.id, opSource: 5 })
          this.getList()
        })
    },
    goAddBank() {
      if (!this.userInfos.realName) {
        this.$dialog.confirm({
          message: '添加银行卡需先完成实名认证，请先完成实名认证',
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          confirmButtonColor: '#FF7700'
        })
          .then(async() => {
            this.$router.push({ path: '/userAuthentication', query: { time: new Date().getTime() }})
          })
          .catch(async() => {
          })
        return
      } else {
        this.$router.push({ path: '/bankAdd' })
      }
    },
    async getList() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await getCardList()
      this.list = res.datas
      loading.clear()
    }
  }
}
</script>
<style lang="scss" scoped>
.bank-list {
  padding: 0 16px;
  .head {
    display: flex;
    align-items: center;
    padding: 16px 0 10px 0;
    .title {
      font-size: 14px;
      color: #32465A;
    }
    .num {
      padding: 0 8px;
      height: 20px;
      line-height: 20px;
      margin-left: 10px;
      border: 1px solid #91A0B4;
      border-radius: 10px;
      font-size: 12px;
      color: #91A0B4;
    }
  }
  .list {
    .item {
      position: relative;
      display: flex;
      align-items: center;
      width: 343px;
      height: 124px;
      margin-bottom: 14px;
      background: url('~@/assets/user/card_bg@2x.png');
      background-size: 100%;
      .icon {
        width: 50px;
        height: 50px;
        margin-left: 24px;
        border-radius: 8px;
        background: #FFDDC2;
      }
      .cont {
        padding-left: 16px;
        color: #fff;
        .tt {
          font-size: 16px;
        }
        .ct {
          padding-top: 10px;
          font-size: 20px;
        }
      }
      .del {
        position: absolute;
        top: 0;
        right: 0;
        img {
          width: 31px;
        }
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 15px;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.06);
    border-radius: 8px;
    .title {
      flex: 1;
      font-size: 14px;
      color: #666666;
    }
    .icon {
      font-size: 20px;
      color: #ff7700;
    }
  }
}
</style>
